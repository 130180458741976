// import ReactDOM from 'react-dom'; // For React 17
import axios from 'axios';
// import React from 'react';
import { createRoot } from 'react-dom/client'; // For React 18
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App/App';
import { AuthContextProvider } from './contexts/authContext';
import { ThemeContextProvider } from './contexts/themeContext';
import SetAuthToken from './helpers/setAuthToken';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import './styles/styles.scss';

let persistor = persistStore(store);

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// localStorage
let persistedData = localStorage.getItem('persist:auth');
if (persistedData) {
	const data = JSON.parse(persistedData);
	const token = data?.auth ? JSON.parse(data.auth).accessToken : '';

	if (token) {
		SetAuthToken(token);
	}
}

// Auto Logout form axios
axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			localStorage.removeItem('persist:auth');
			window.location.href = '/auth-pages/login';
			window.location.reload();
		}
		return Promise.reject(error);
	},
);

const children = (
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<AuthContextProvider>
				<ThemeContextProvider>
					<Router>
						{/* <React.StrictMode> */}
						<App />
						{/* </React.StrictMode> */}
					</Router>
				</ThemeContextProvider>
			</AuthContextProvider>
		</PersistGate>
	</Provider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
