import { apiSlice } from '../api/apiSlice';
import { addCounter } from './dashboardSlice';

export const dashboardApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getDashboard: builder.query({
			query: () => `/dashboard`,
			providesTags: ['dashboard'],
			async onQueryStarted(arg, { queryFulfilled, dispatch }) {
				try {
					const result = await queryFulfilled;
					dispatch(addCounter(result.data.counter));
				} catch (err) {
					// do nothing
				}
			},
		}),
		getVehicleChart: builder.query({
			query: () => `/monthly-sales`,
			keepUnusedDataFor: 1800,
		}),
	}),
});

export const { useGetDashboardQuery, useGetVehicleChartQuery } = dashboardApi;
